import Vue from "vue";

/*TITLE*/
document.title = "Blue Air Residence | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Blue Air Residence";
Vue.prototype.$subtitle = "Apartamentos de nueva construcción";

/*INTRO*/
Vue.prototype.$promoter = "Blue Air Residence";
Vue.prototype.$introSubtitle = "Apartamentos de nueva construcción";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-vela-grey--20230112120102.jpg";
Vue.prototype.$image_kitchen = "cocina-vela-grey-zocalo-frentes-blanco-nubol-encimera-krion-snow-white-entrepano--20230112120110.jpg";
Vue.prototype.$image_bath1 = "banop-vela-grey-revestimiento-vela-grey--20230112120124.jpg";
Vue.prototype.$image_bath2 = "banosec-vela-grey-revestimiento-vela-grey--20230112120130.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-vela-grey--20230112120102.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-vela-grey-zocalo-frentes-blanco-nubol-encimera-krion-snow-white-entrepano--20230112120110.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-vela-grey-revestimiento-vela-grey--20230112120124.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-vela-grey-revestimiento-vela-grey--20230112120130.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/BLUE_AIR/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/774960394";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=cami%20de%20benimussa%20islas%20baleares%2007820",
    text: "Cami de Benimussa, Islas Baleares, 07820",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avenida%20ignacio%20wallis%2031%20–%206ª%20planta%20–%20edificio%20registro%20mercantil%2007800%20ibiza%20islas%20baleares%20",
    text: "Avenida Ignacio Wallis, 31 – 6ª planta – Edificio Registro Mercantil 07800 Ibiza (Islas Baleares)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:602438254",
    text: "602438254",
  },
  {
    icon: "mdi-email",
    link: "mailto:blueairresidence@gmail.com",
    text: "blueairresidence@gmail.com",
  },
];
